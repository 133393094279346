<template>
  <div class="video">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <div class="video-top">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: `/classdetail?id=${this.$route.query.courseId}` }">返回课程
        </el-breadcrumb-item>
      </div>
      <!--      <span v-if="unlockTime!==0"-->
      <!--            style="float: right;margin-right: 20px;">学习{{ unlockTime }}分钟后解锁下一学习课时！</span>-->
    </el-breadcrumb>
    <div class="cont">
      <video v-if="videoFileid" :id="tcPlayerId" :width="clientWidth" :height="clientHeight" preload="auto" playsinline
        webkit-playsinline="false" x5-playinline></video>
    </div>
    <camera-popup :showPopup="showCameraPopup" :loading="false" @image-blob="onImageCaptured"></camera-popup>
    <el-dialog title="验证失败" :visible.sync="dialogVisible" width="40%" @close="dialogVisible = false"
      :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <div class="content">
        {{ content }}
      </div>
      <span slot="footer" v-if="compareCount < 6" class="dialog-footer">
        <el-button type="danger" @click="rePhoto">重新拍照</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="showViewTimeDialog" title="提示"
      @close="showViewTimeDialog = false; formatDuringViewTime = ''">
      <div>该课程已学习了{{ formatDuringViewTime }},是否跳转到该进度</div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="showViewTimeDialog = false">取消</el-button>
          <el-button type="primary" @click="jumpProgress">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  getVodArguments,
  finishPeriod,
  recordError,
  startPeriod,
  faceCompare,
  getCourseMonitorSetting
} from '@/api/course'
import CameraPopup from '@/components/Monitor/index.vue'
import { uploadPrivateIdentifyImage } from "@/utils/upload";
import { formatDuring } from "@/utils/index";


export default {
  components: {
    CameraPopup,
  },
  name: 'Video',
  data () {
    return {
      timer: null,
      compareCount: 0,
      minutes: 0,
      seconds: 5,
      content: '',
      dialogVisible: false,
      lastViewTime: 0,
      startPeriodCalled: false,
      canFinishLearning: false,
      canStartTimer: true,
      heartbeatCount: 0,
      tcPlayerId: 'tcPlayer' + Date.now(),
      showCameraPopup: false,
      canPlayVideo: true,
      player: null,
      videoFileid: '',
      videoAppid: '',
      videoSign: '',
      courseId: '', // 课程id
      periodId: '', // 课时id
      clientWidth: '', //
      clientHeight: '',
      canSkip: 0, //0否 1是
      canDragProgress: 0, //0否 1是
      unlockTime: 0, //解锁时间
      speedLimit: 2, //限速
      learningStatus: 3, //学习状态 1未开始 2进行中 3已完成

      //监控设置
      needMonitor: null, //是否需要监控 1不需要 0需要
      needStartMonitor: null, //是否需要开启监控 1不需要 0需要
      needEndMonitor: null, //是否需要结束监控 1不需要 0需要
      intervalTime: null, //监控间隔时间  0代表间隔不需要监控

      //记录播放进度
      localStorageProgressKey: '',
      viewTime: 0,//播放进度
      showViewTimeDialog: false,
      formatDuringViewTime: '00:00:00',
    }
  },
  async mounted () {
    this.clientWidth = document.documentElement.clientWidth
    this.clientHeight = document.documentElement.clientHeight
    this.courseId = this.$route.query.courseId
    this.periodId = this.$route.query.periodId
    this.localStorageProgressKey = this.courseId + '_' + this.periodId;
    console.log(this.localStorageProgressKey, ' this.localStorageProgressKey ')
    window.addEventListener('blur', this.handleBlur);
    window.addEventListener('focus', this.handleFocus);
    window.addEventListener('beforeunload', this.beforeUnloadHandler);
    await this.getVodArguments()
    await this.init()
    await this.monitorSettings();


  },
  computed: {
    playControlClass () {
      return 'hide-play-control';
    },
  },

  methods: {
    rePhoto () {
      this.dialogVisible = false
      this.showCameraPopup = true
    },
    beforeUnloadHandler () {
      console.log("111关闭页面开始111")
      if (this.lastViewTime > 0) {
        this.setViewTime();
      }
    },
    setViewTime () {
      const params = {
        periodId: this.periodId,
        courseId: this.courseId,
        unlockTime: this.unlockTime,
        viewTime: this.lastViewTime
      }
      finishPeriod(params);
    },
    monitorSettings () {
      const _this = this;
      const params = {
        courseId: this.courseId
      }
      getCourseMonitorSetting(params).then(res => {
        console.log("getCourseMonitorSetting", res)
        if (res.status === 200) {
          if (res.data.code === 0) {
            // debugger
            _this.needMonitor = res.data.data.needMonitor
            _this.needStartMonitor = res.data.data.needStartMonitor
            _this.needEndMonitor = res.data.data.needEndMonitor
            _this.intervalTime = res.data.data.intervalTime
          }
        }


        // this.showCameraPopup = true

        if (this.needMonitor === 0) {
          this.canPlayVideo = false
          this.showCameraPopup = true
        } else {
          this.canPlayVideo = true
        }
      });
    },
    handleBlur () {
      this.player.pause();
    },
    handleFocus () {
      if (this.showCameraPopup) {
        return
      }
      this.player.play();
    },
    showDialog () {
      // debugger
      if (this.needMonitor === 0) {
        if (this.player) {
          this.player.pause();
        }

        // 显示弹窗
        this.showCameraPopup = true;
      }
    },
    onImageCaptured (imageBlob) {
      // 将dataURL转换为Blob
      // 将Blob转换为File对象
      const file = new File([imageBlob], this.identifyImgName(), { type: "image/jpeg" });
      uploadPrivateIdentifyImage(file, (url) => {
        let params = {
          periodId: this.periodId,
          courseId: this.courseId,
          imageUrl: url,
          freqFlag: this.compareCount < 5 ? 0 : 1
        }
        faceCompare(params).then(res => {
          console.log(res)
          if (res.status === 200) {
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '验证成功,您可以开始学习了！',
                duration: 1000,
              })
              this.compareCount = 0;
              this.showCameraPopup = false
              if (this.player) {
                this.player.play();
              }
            } else {
              if ("未登录" === res.data.message) {
                this.content = "请重新登录后再进行本课程的学习"
              } else {
                this.compareCount++;
                this.content = "用户身份验证失败,请重新拍照并上传"
                if (this.compareCount > 5) {
                  this.content = "您的身份识别操作已超过次数限制,请暂停学习"
                  setTimeout(() => {
                    this.goOut()
                  }, 3000)
                }
              }
              this.showCameraPopup = false
              this.dialogVisible = true
            }
          }
        })

      });
    },
    identifyImgName () {
      //date.new() + 随机数
      return Date.now() + Math.floor(Math.random() * 10000) + '.jpeg';
    },
    getVodArguments () {
      const obj = {
        periodId: this.periodId, //课时id
        courseId: this.courseId, //课程id
      }
      return new Promise((resolve, reject) => {
        getVodArguments(obj).then(res => {
          if (res.data.data.needRealname) {
            this.$message({
              type: 'error',
              message: '该视频需要实名才能观看！',
              duration: 1000,
            })
          }
          if (res.status === 200) {
            if (res.data.code === 0) {
              const {
                appId,
                fileId,
                signature,
                canSkip,
                canDragProgress,
                unlockTime,
                speedLimit,
                learningStatus,
                viewTime
              } = res.data.data
              this.videoFileid = fileId
              this.videoAppid = appId
              this.videoSign = signature
              this.canSkip = canSkip
              this.canDragProgress = canDragProgress
              this.unlockTime = unlockTime
              this.speedLimit = speedLimit
              this.learningStatus = learningStatus
              this.viewTime = viewTime
              this.formatDuringViewTime = formatDuring(viewTime * 1000)
              console.log(formatDuring(viewTime * 1000), viewTime, 'formatDuring(viewTime)')
              resolve()
            } else {
              this.$message({
                type: 'error',
                message: res.data.message,
                duration: 1000,
                onClose: () => {
                  this.$router.go(-1) //回到上一页 history.go(-1) 是回到浏览器上一页。
                }
              })
            }

          } else {
            reject()
          }
        })
      })

    },
    async goOut () {
      await this.$store.dispatch('user/logout').then(res => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.code === 0) {
            setTimeout(() => {
              location.reload()
            }, 3000)
            this.$router.push({
              path: '/'
            })
          }
        }
      })
    },
    //
    init () {
      this.$nextTick(() => {
        this.getVideoLang(this.videoFileid, this.videoAppid, this.videoSign)
        // this.getVideoLang(self.videoFileid, self.videoAppid, self.psign)
      })

      /*let self = this
      setTimeout(() => {
        self.videoFileid = '3701925921299637010'
        self.videoAppid = '1500005696'
        self.videoSign = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6MTUwMDAwNTY5NiwiZmlsZUlkIjoiMzcwMTkyNTkyMTI5OTYzNzAxMCIsImN1cnJlbnRUaW1lU3RhbXAiOjE2MjY4NjAxNzYsImV4cGlyZVRpbWVTdGFtcCI6MjYyNjg1OTE3OSwicGNmZyI6InByaXZhdGUiLCJ1cmxBY2Nlc3NJbmZvIjp7InQiOiI5YzkyYjBhYiJ9LCJkcm1MaWNlbnNlSW5mbyI6eyJleHBpcmVUaW1lU3RhbXAiOjI2MjY4NTkxNzksInN0cmljdE1vZGUiOjJ9fQ.Bo5K5ThInc4n8AlzIZQ-CP9a49M2mEr9-zQLH9ocQgI'
        self.$nextTick(() => {
          self.getVideoLang(self.videoFileid, self.videoAppid, self.videoSign)
        })
      }, 2000)*/
    },
    //记录播放进度
    setlocalStorageProgress (time) {
      let _data = localStorage.localStorageProgressKey;
      if (!_data) {
        _data = {};
      } else {
        _data = JSON.parse(_data);
      }
      if (!_data[this.localStorageProgressKey]) {
        _data[this.localStorageProgressKey] = 0;
      }
      _data[this.localStorageProgressKey] = time;

      localStorage.localStorageProgressKey = JSON.stringify(_data)
    },
    getlocalStorageProgress (player, viewTime, learningStatus) {
      // let _data = localStorage.localStorageProgressKey;
      // if (!_data) {
      //   _data = {};
      // } else {
      //   _data = JSON.parse(_data);
      // }
      // if (_data[this.localStorageProgressKey]) {
      //   player.currentTime(_data[this.localStorageProgressKey])
      // }
      console.log(viewTime, learningStatus)
      if (learningStatus == 2 && this.formatDuringViewTime.length > 0) {
        this.showViewTimeDialog = true;
      }
      // player.currentTime(viewTime)
    },
    jumpProgress () {
      this.player.currentTime(this.viewTime)
      this.showViewTimeDialog = false;
      this.formatDuringViewTime = '';
    },
    startTimer () {
      this.canStartTimer = false;
      this.timer = setInterval(this.updateTime, 1000);
    },

    updateTime: function () {
      if (this.seconds === 0) {
        if (this.minutes === 0) {
          this.finish();
          return;
        }
        this.minutes--;
        this.seconds = 59;
      } else {
        this.seconds--;
      }
    },
    heartbeat: function (heartbeat) {
      const params = {
        periodId: this.periodId,
        courseId: this.courseId,
        unlockTime: this.unlockTime,
        heartbeat: heartbeat
      }
      finishPeriod(params).then(res => {
        if (res.status === 200) {
          if (res.data.code === 0) {
            //finishPeriod 接口调用成功
            console.log("心跳操作！")
          }
        }
      })
    },
    finish: function () {
      const params = {
        courseId: this.courseId,
        periodId: this.periodId,
        unlockTime: this.unlockTime,
      }
      finishPeriod(params).then(res => {
        // _this.setlocalStorageProgress(0)
        if (res.status === 200) {
          if (res.data.code === 0) {
            //finishPeriod 接口调用成功
            // console.log("结束学习！" + res)
            // console.log("结束调用finish")
          } else {
            // _this.canFinishLearning = true;
            recordError(params);
          }
        }

      })
      clearInterval(this.timer);
      // alert('时间到！');
    },


    // 初始化腾讯云播放器
    getVideoLang (fileID, appID, psign) {
      let number = 0
      // debugger
      const _this = this;
      //根据speedlimit生成数组，用于控制倍速
      let playbackRates = []
      for (let i = 1; i <= this.speedLimit; i += 0.25) {
        playbackRates.push(i)
      }
      const controlBal = {
        // 禁止用户拖动进度条
        progressControl: {
          seekBar: this.canDragProgress === 1
        },
        // 只展示 1 倍和 1.5 倍两个选项
        playbackRateMenuButton: {
          playbackRates: playbackRates
        }
      }
      const playerParam = {
        fileID: fileID,
        appID: appID,
        psign: psign,
        controlBar: controlBal
      }
      this.player = window.TCPlayer(this.tcPlayerId, playerParam)
      this.player.on('timeupdate', function () {
        _this.lastViewTime = this.currentTime();
        // console.log("this.lastViewTime", _this.lastViewTime)
        // console.log("_this.currentTime()",Math.floor(this.currentTime()))
        _this.setlocalStorageProgress(this.currentTime())
        // 视频开始播放后
        if (_this.learningStatus === 1) {
          if (this.currentTime() >= 1 && !_this.startPeriodCalled) {
            // 调用接口
            console.log("开始调用")
            _this.startPeriodLearn();
            _this.startPeriodCalled = true;
            // 取消事件监听器，防止重复调用
            console.log("结束调用")
          }
        }
        if (_this.learningStatus === 1 || _this.learningStatus === 2) {
          if (_this.canStartTimer && _this.unlockTime !== 0) {
            console.log("开始倒计时")
            _this.minutes = _this.unlockTime;
            _this.startTimer();
          }
          // console.log("准备调用finish", _this.unlockTime * 60)
          if (this.currentTime() >= _this.unlockTime * 60 && !_this.canFinishLearning && _this.unlockTime !== 0) {
            // if (this.currentTime() >=  10) {
            // 调用接口
            _this.canFinishLearning = true
            // 调用接口
            console.log("倒计时调用heartbeat", this.currentTime() / 60)
            const params = {
              courseId: _this.courseId,
              periodId: _this.periodId,
              unlockTime: _this.unlockTime,
            }
            finishPeriod(params).then(res => {
              // _this.setlocalStorageProgress(0)
              if (res.status === 200) {
                if (res.data.code === 0) {
                  //finishPeriod 接口调用成功
                  console.log("结束学习！" + res)
                  _this.canFinishLearning = true
                  console.log("结束调用finish")
                } else {
                  _this.canFinishLearning = false;
                  // _this.canFinishLearning = true;
                  recordError(params);
                }
              }
            })
            // _this.heartbeat(this.currentTime() / 180)

            // 取消事件监听器，防止重复调用
            //  this.off('timeupdate');
          }
          if (Math.floor(this.currentTime()) % 180 === 0) {
            let count = Math.floor(this.currentTime()) / 180;
            console.log("每3分钟触发一次心跳操作", count)
            if (count !== _this.heartbeatCount) {
              //每3分钟触发一次心跳操作
              _this.heartbeat(count * 3);
              _this.heartbeatCount = count;
            }
          }
        }
        let currentTime = parseInt(_this.player.currentTime());
        if (_this.intervalTime !== 0) {
          if (currentTime % (_this.intervalTime * 60) === 0 && currentTime !== number * _this.intervalTime * 60) {
            number++;
            _this.showDialog();
          }
        }
        const progressBar = document.getElementById('progressBar');

        if (progressBar) {
          const progress = (this.currentTime() / this.duration()) * 100;
          progressBar.style.width = progress + '%';
        } else {
          console.warn('Progress bar element not found.');
        }
        if (_this.player.playbackRate() > 2) {
          _this.player.playbackRate(1)
        }
      });
      this.player.on('ratechange', () => {
        if (this.player.playbackRate() > 2) {
          this.player.playbackRate(1)
        }
      });
      this.player.on('ended', () => {
        // 视频播放完成后
        _this.setlocalStorageProgress(0)
        _this.setViewTime();
      });

      this.player.on('play', () => {
        //开始播放时先查找本地有没有存播放进度
        //6-25修改，不读取本地的进度，改为读取接口返回的
        console.log('viewTimeviewTimeviewTime', this.viewTime)
        _this.getlocalStorageProgress(this.player, this.viewTime, this.learningStatus)
        if (_this.unlockTime === 0 && !_this.canFinishLearning && _this.learningStatus !== 3) {
          const params = {
            courseId: _this.courseId,
            periodId: _this.periodId,
            unlockTime: _this.unlockTime,
          }
          finishPeriod(params).then(res => {
            // _this.setlocalStorageProgress(0)
            if (res.status === 200) {
              if (res.data.code === 0) {
                //finishPeriod 接口调用成功
                console.log("结束学习！" + res)
                _this.canFinishLearning = true
                console.log("结束调用finish")
              } else {
                _this.canFinishLearning = false;
                // _this.canFinishLearning = true;
                recordError(params);
              }
            }
          })
        }
      });
      // this.player.ready(function() {
      //   // 获取播放器的 playToggle 组件
      //   const playToggle = this.player.controlBar.playToggle;
      //
      //   // 缓存原始的 handleClick 方法
      //   const originalHandleClick = playToggle.handleClick;
      //
      //   // 覆盖 playToggle 的 handleClick 方法
      //   playToggle.handleClick = function(event) {
      //     // 在这里编写新的点击事件逻辑
      //     console.log("播放/暂停按钮被点击了");
      //
      //     // 调用原始的 handleClick 方法以保持播放/暂停功能
      //     originalHandleClick.call(playToggle, event);
      //   };
      // });
    },
    startPeriodLearn () {
      const params = {
        courseId: this.courseId,
        periodId: this.periodId,
      }
      // 调用接口
      startPeriod(params).then(res => {
        console.log("开始学习！" + res)
      })

      //重写vjs-button-icon点击事件
    },

    // init() {
    //   var player = TCPlayer("player-container-id", {
    //     // player-container-id 为播放器容器 ID，必须与 html 中一致
    //     fileID: "3701925921299637010", // 请传入需要播放的视频 fileID（必须）
    //     appID: "1500005696", // 请传入点播账号的 appID（必须）
    //     //私有加密播放需填写 psign， psign 即播放器签名，签名介绍和生成方式参见链接：https://cloud.tencent.com/document/product/266/42436
    //     psign:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6MTUwMDAwNTY5NiwiZmlsZUlkIjoiMzcwMTkyNTkyMTI5OTYzNzAxMCIsImN1cnJlbnRUaW1lU3RhbXAiOjE2MjY4NjAxNzYsImV4cGlyZVRpbWVTdGFtcCI6MjYyNjg1OTE3OSwicGNmZyI6InByaXZhdGUiLCJ1cmxBY2Nlc3NJbmZvIjp7InQiOiI5YzkyYjBhYiJ9LCJkcm1MaWNlbnNlSW5mbyI6eyJleHBpcmVUaW1lU3RhbXAiOjI2MjY4NTkxNzksInN0cmljdE1vZGUiOjJ9fQ.Bo5K5ThInc4n8AlzIZQ-CP9a49M2mEr9-zQLH9ocQgI',
    //   });
    // }
  },
  beforeDestroy () {
    // this.player.dispose()
    this.player = null;
    window.removeEventListener('unload', this.beforeUnloadHandler); //移除监听
  },
  // finishPeriodLearn() {
  //   const params = {
  //     courseId: this.courseId,
  //     periodId: this.periodId,
  //   }
  //   // 调用接口
  //   finishPeriod(params).then(res => {
  //     console.log("结束学习！" + res)
  //   })
  // },

}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.video {
  height: 100%;
  background-color: #f5f5f5;
  padding-top: 20px;

  .video-top {
    margin-left: 20px;
  }

  .cont {
    margin-top: 20px;
  }
}

.content {
  font-size: 1.2em;
  text-align: center;
  margin: 20px 0;
}

.dialog-footer {
  display: flex;
  justify-content: center;
}

.hide-play-control .vjs-play-control .vjs-button-icon {
  display: none !important;
}
</style>